import React from "react";

export function CtaCard({
  src,
  linkHref,
  linkBody,
  heading,
  body,
  align = "left",
}) {
  return (
    <div className={`cta-card cta-card--${align}`}>
      <img src={src} />
      <div className="cta-card__content">
        <h3>{heading}</h3>
        <p>{body}</p>
        <a
          className="btn"
          href={linkHref}
          target="_blank"
          rel="noopener noreferrer"
        >
          {linkBody}
        </a>
      </div>
    </div>
  );
}
