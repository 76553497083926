import React, { useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";
import TicketShop from "components/TicketShop";
import { Hero } from "components/Hero";
import { ticketShopScript } from "constants/ticketShopScript";
import Logo from "../svgs/Logo.svg";
import YouTube from "react-youtube";
import { CtaCard } from "components/CtaCard";
import { MailingCard } from "components/MailingCard";
import { infoEmail } from "components/Emails";

const youtubeVideoId = "XaoeDeKXkLU";

function IndexPage(p) {
  const title = "Out Out Events";
  const description = ``;

  const [videoPlaying, setVideoPlaying] = useState(false);
  const [hideThumbnail, setHideThumbnail] = useState(false);
  const player = useRef();

  useEffect(() => {
    // takes 3 seconds for youtube to hide their crap on top of the video
    let timeout;

    if (videoPlaying) {
      timeout = setTimeout(() => {
        setHideThumbnail(true);
        player.current.seekTo(0);
      }, 3000);
    }

    return () => {
      if (videoPlaying) {
        clearTimeout(timeout);
      }
    };
  }, [videoPlaying]);

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={title}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:url`,
            content: p.location.href,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:image`,
            content: ``,
          },
          {
            name: "facebook-domain-verification",
            content: ``,
          },
        ]}
      />
      <Hero
        background={
          <div className="hero__video">
            <YouTube
              videoId={youtubeVideoId}
              opts={{
                playerVars: {
                  autoplay: 1,
                  controls: 0,
                  loop: 1,
                  mute: 1,
                  modestbranding: 0,
                },
              }}
              onPlay={() => {
                setVideoPlaying(true);
              }}
              onReady={(e) => {
                player.current = e.target;
                e.target.playVideo();
              }}
              onStateChange={(e) => {}}
              onEnd={(e) => {
                // loop param doesn't properly work so :shrug:
                e.target.playVideo();
              }}
            />
            <img
              src="/preview.jpg"
              style={{ opacity: hideThumbnail ? 0 : 1 }}
            />
          </div>
        }
      >
        <div className="hero__logo">
          <Logo />
          <p>If you’re not out out, what you doing out?</p>
        </div>
      </Hero>

      <div id="tickets" className="home__ticketshop">
        <TicketShop scriptString={ticketShopScript} />
      </div>

      <div className="home__cta-card">
        <CtaCard
          src="/rep.jpg"
          heading="Become a rep"
          body="Get in touch if you want to rep our events"
          linkHref="https://reps.fixr.co/discover/organisers/172645050"
          linkBody="Become a rep"
          align="right"
        />
      </div>

      <div className="home__cta-card">
        <CtaCard
          src="/sponsor.jpg"
          heading="Sponsor our events"
          body="Get in touch if you want to sponsor our events"
          linkHref={`mailto:${infoEmail}`}
          linkBody="For Sponsors"
        />
      </div>

      {/* <div className="home__cta-card">
        <MailingCard />
      </div> */}

      <div className="home__gallery">
        <h3>Gallery</h3>
        <div className="home__gallery-photos">
          <div className="home__gallery-photo">
            <img src="/gallery/IMG_2139.png" />
          </div>
          <div className="home__gallery-photo">
            <img src="/gallery/IMG_2140.png" />
          </div>
          <div className="home__gallery-photo">
            <img src="/gallery/IMG_2141.png" />
          </div>
          <div className="home__gallery-photo">
            <img src="/gallery/IMG_3085.png" />
          </div>
          <div className="home__gallery-photo">
            <img src="/gallery/IMG_3086.png" />
          </div>
          <div className="home__gallery-photo">
            <img src="/gallery/IMG_3087.png" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default IndexPage;
