import React from "react";

export function Hero({ children = null, color = "", background = null }) {
  return (
    <div className={`hero hero--${color}`}>
      {background}
      <div className="hero__gradient"></div>
      <div className="hero__content">{children}</div>
    </div>
  );
}
